import { BaseController } from './BaseController'
import { components } from 'Types/api'

export type CostObject = components['schemas']['CostObject']
export type Funding = components['schemas']['ApplicationFunding']

export class FinanceController extends BaseController {
  readonly url_finance_cost_object: string

  constructor () {
    super()

    this.url_finance_cost_object = `${this.apiHost}/${this.apiPath}/finance/cost-object`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  public async getFinanceObject (code: string): Promise<CostObject> {
    const UID = Math.floor(Math.random() * 100)
    const response = await this.useFetch('GET', 'getFinanceObject' + UID.toString(), `${this.url_finance_cost_object}/${code}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
