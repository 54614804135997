import { BaseController } from './BaseController'

export class UploadController extends BaseController {
  readonly url_people: string

  constructor () {
    super()

    this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  public async fetchS3Bucket (url: string): Promise<any> {
    const response = await this.useFetch('GET', 'fetchS3Bucket', url, null, undefined, true)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  public async uploadToS3Bucket (url: string, file: File): Promise<any> {
    const response = await this.useFetch('PUT', `put/upload${url}`, url, file, undefined, true)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
