
import { AuthProvider } from '@mit/aws-react'
import appConfig from "../app-config-global";

class AuthService {

  getToken = async (): Promise<any> => {
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    return authorizationToken;
  }

  logout = async (): Promise<any> => {
    console.log('AuthService.logout()')
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }
}

const authService = new AuthService()
export default authService
