import React from 'react'
import { Alert as MuiAlert, AlertColor, AlertProps, Icon } from '@material-ui/core'

type HuiAlertProps = {
  severity: AlertColor
  variant: 'standard' | 'filled' | 'outlined'
} & AlertProps

export const Alert: React.FC<HuiAlertProps> = ({ severity, variant, ...otherProps }) => {
  const { children } = otherProps
  const alertConfig = {
    ...otherProps
  }

  if (severity === 'success') return (<MuiAlert icon={<Icon className='fas fa-check-circle' fontSize='small' />} severity={severity} variant={variant} {...alertConfig}>{children}</MuiAlert>)
  if (severity === 'info') return (<MuiAlert icon={<Icon className='fas fa-info-circle' fontSize='small' />} severity={severity} variant={variant} style={{ color: '#75787b' }} {...alertConfig}>{children}</MuiAlert>)
  if (severity === 'warning') return (<MuiAlert icon={<Icon className='fas fa-exclamation-triangle' fontSize='small' style={{ width: 'auto' }} />} severity={severity} variant={variant} {...alertConfig}>{children}</MuiAlert>)
  return (<MuiAlert icon={<Icon className='fas fa-exclamation-circle' fontSize='small' />} severity={severity} variant={variant} {...alertConfig}>{children}</MuiAlert>)
}
