import { BaseController } from './BaseController'

export interface PersonAutocomplete {
  affiliation: string
  department?: string
  display_name: string
  email?: string
  first_name: string
  initials?: string
  kerberos: string
  kerberos_id: string
  krb_name: string
  last_name: string
  mitHROrganisationId?: string
  mit_id: string
  mitid: string
  office_location?: string
  phone_number?: number
  source: string
}

export interface Person {
  id: string
  name: string
}

export class PeopleController extends BaseController {
  readonly url_people: string

  constructor () {
    super()

    this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  public async fetchPeopleAws (query: string): Promise<PersonAutocomplete[]> {
    const response = await this.useFetch('GET', 'students', this.url_people + query + `&allStatus=true`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
