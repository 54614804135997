import { persist } from 'easy-peasy'

// APP SPECIFIC STORES - EDIT THESE OR ADD MORE HERE:
import { IAppConfigModel, AppConfigModel } from './AppConfig'
import { IGlobalModel, GlobalModel } from './Global'
import { ILocalStorageModel, LocalStorageModel } from './LocalPresist'
import { ISessionStorageModel, SessionStorageModel } from './SessionPresist'
import { IProfileModel, ProfileModel } from './Profile'
import { IVisitingModel, VisitingModel } from './Visiting'
import { ILookUpModel, LookUpModel } from './LookUp'
import { IReloadModel, ReloadModel } from './Reload'
import { IStatusModel, StatusModel } from './Status'

export interface Model {
  AppConfigModel: IAppConfigModel
  GlobalModel: IGlobalModel
  LocalStorageModel: ILocalStorageModel
  ProfileModel: IProfileModel
  SessionStorageModel: ISessionStorageModel
  VisitingModel: IVisitingModel
  LookUpModel: ILookUpModel
  ReloadModel: IReloadModel
  StatusModel: IStatusModel
}

export const model: Model = {
  // FOR APP CONFIG MANEGEMENT - AppConfigModel
  AppConfigModel,
  // FOR GLOBAL STATE MANAGEMENT - GlobalModel
  GlobalModel,
  // FOR PROFILE STATE MANAGEMENT - ProfileModel
  ProfileModel,
  // FOR LOCALSTORAGE MANAGEMENT - LocalStorageModel
  LocalStorageModel: persist(LocalStorageModel, {
    storage: 'localStorage'
  }),
  // FOR SESSIONSTORAGE MANAGEMENT - SESSIONSTORAGE
  SessionStorageModel: persist(SessionStorageModel, {
    storage: 'sessionStorage'
  }),
  // FOR VISITNG STATE MANAGEMENT - VisitingModel
  VisitingModel,
  // FOR LOOKUP STATE MANAGEMENT - LookUpModel
  LookUpModel,
  // FOR RELOAD STATE MANAGEMENT - ReloadModel
  ReloadModel,
  // FOR STATUS TAB STATE MANAGEMENT - Status Model
  StatusModel
}
