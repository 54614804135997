import { BaseApiController, AppConfig } from '@mit/aws-react'
import authService from '../../Services/AuthService'
import apiConfig from '../api-config'
import appConfig from '../../app-config-global'

// Override any methods from BaseApiController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  public readonly apiPath: string | undefined
  public readonly appConfig: AppConfig
  public readonly apiHost: string
  public readonly apiPathDigitalId: string | undefined

  public constructor() {
    super()

    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = (isGlobalEnabled ? 'global' : 'regional')

    this.apiHost = apiConfig[configKey].apiHost
    this.apiPath = apiConfig[configKey].paths.apiPathVisitingStudent
    this.apiPathDigitalId = apiConfig[configKey].paths.apiPathDigitalId

    this.appConfig = (appConfig as unknown) as AppConfig

  }

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  public async getAuthorizationHeaders(): Promise<Record<string, string>> {
    let token = await authService.getToken()
    return {
      Authorization: `Bearer ${token}`
    }
  }
}
