import { BaseController } from 'Api/controller'

import { Profile } from 'Types'

type pictureResolution = 'low' | 'high'

export class ProfileController extends BaseController {
  public async getProfile (): Promise<Profile> {
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProfilePicture (): Promise<Blob> {
    const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getUserProfilePicture (mitId: string, resolution?: pictureResolution): Promise<Blob> {
    let response: any
    const pathParams: String[] = []

    if (resolution !== undefined) pathParams.push(`resolution=${resolution}`)

    if (pathParams.length > 0) {
      response = await this.useFetch('GET', 'picture' + mitId, `${this.apiHost}/${this.apiPathDigitalId}/picture/${mitId}?${pathParams}`)
    } else {
      response = await this.useFetch('GET', 'picture' + mitId, `${this.apiHost}/${this.apiPathDigitalId}/picture/${mitId}`)
    }

    if (response.status === 200) {
      return response.blob()
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
