import { getStage, getConfigFromFile } from '@mit/aws-react'
import { Application, ApplicationExt, Config } from 'Api/controller'
import dayjs from 'dayjs'
import appConfig from '../app-config-global'

type EnvReturn = 'dev' | 'staging' | 'production'
type EnvUrlReturn = '-develop' | '-release' | ''

export const getEnv = (): EnvReturn => {
  var config: any = getConfigFromFile(appConfig);
  var stage: any = getStage(config);

  switch (stage) {
    case 'dev':
      return 'dev'
    case 'test':
      return 'staging'
    case 'prod':
      return 'production'
  }

  return 'dev'
}

export const getEnvUrl = (): EnvUrlReturn => {
  var config: any = getConfigFromFile(appConfig);
  var stage: any = getStage(config);

  switch (stage) {
    case 'dev':
      return '-develop'
    case 'test':
      return '-release'
    case 'prod':
      return ''
  }

  return '-develop'
}

export const hidePaymentOptions = (application: ApplicationExt, configs: Config[]): boolean => {
  if (exemptFromFees(application, configs) && application.student.exemptLocation) return true
  return false
}

export const exemptFromFees = (application: Application, configs: Config[]): boolean => {
  const requiredDays = configs.find((config) => config.id === 'MINIMUM_REQUIRED_BILLING_DAYS')?.value ?? '35'
  const visitDays = dayjs(application.datesOfVisit.endDate).diff(dayjs(application.datesOfVisit.startDate), 'day')

  if (application.student.exemptFee || visitDays < parseInt(requiredDays)) {
    return true
  }
  return false
}
