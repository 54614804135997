import { Action, action } from 'easy-peasy'

export type ActiveDrawer = 'None' | 'ShowWhatsNew' | 'Application' | 'Verify'
export type ActiveModal = 'None' | 'VisitngDates' | 'Capacities'

interface State {
  auth: object
  activeDrawer: ActiveDrawer
  activeModal: ActiveModal
}
interface Actions {
  setAuth: Action<this, object>
  setActiveDrawer: Action<this, ActiveDrawer>
  setActiveModal: Action<this, ActiveModal>
}
// eslint-disable-next-line
interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},
  activeDrawer: 'None',
  activeModal: 'None',

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),

  setActiveDrawer: action((state, payload) => {
    state.activeDrawer = payload
  }),

  setActiveModal: action((state, payload) => {
    state.activeModal = payload
  })
}
