import { BaseController } from './BaseController'
import { components } from 'Types/api'

export type College = components['schemas']['College']
export type Colleges = components['schemas']['Colleges']
export type Department = components['schemas']['Department']
export type Departments = components['schemas']['Departments']
export type Country = components['schemas']['Country']
export type Countries = components['schemas']['Countries']
export type PayType = components['schemas']['PayType']
export type PayTypes = components['schemas']['PayTypes']
export type StundetType = components['schemas']['StudentType']
export type StundetTypes = components['schemas']['StudentTypes']
export type Year = components['schemas']['Year']
export type Years = components['schemas']['Years']
export type VisitingDate = components['schemas']['VisitingDate']
export type VisitingDates = components['schemas']['VisitingDates']
export type USState = components['schemas']['State']
export type USStates = components['schemas']['States']
export type Config = components['schemas']['Config']
export type Configs = components['schemas']['Configs']
export type Lab = components['schemas']['Lab']
export type Labs = components['schemas']['Labs']
export type JointProgram = components['schemas']['JointProgram']
export type JointPrograms = components['schemas']['JointPrograms']
export type Gender = components['schemas']['Gender']
export type Genders = components['schemas']['Genders']

export interface YearType extends Year {
  name: string
}

export class LookUpController extends BaseController {
  readonly url_colleges: string
  readonly url_student_types: string
  readonly url_departments: string
  readonly url_configs: string
  readonly url_countries: string
  readonly url_pay_types: string
  readonly url_years: string
  readonly url_visiting_dates: string
  readonly url_states: string
  readonly url_labs: string
  readonly url_joint_programs: string
  readonly url_genders: string

  constructor() {
    super()

    this.url_colleges = `${this.apiHost}/${this.apiPath}/lookup/colleges`
    this.url_student_types = `${this.apiHost}/${this.apiPath}/lookup/student-types`
    this.url_departments = `${this.apiHost}/${this.apiPath}/lookup/departments`
    this.url_configs = `${this.apiHost}/${this.apiPath}/lookup/configs`
    this.url_countries = `${this.apiHost}/${this.apiPath}/lookup/countries`
    this.url_pay_types = `${this.apiHost}/${this.apiPath}/lookup/pay-types`
    this.url_years = `${this.apiHost}/${this.apiPath}/lookup/years`
    this.url_visiting_dates = `${this.apiHost}/${this.apiPath}/lookup/visiting-dates`
    this.url_states = `${this.apiHost}/${this.apiPath}/lookup/states`
    this.url_labs = `${this.apiHost}/${this.apiPath}/lookup/labs`
    this.url_joint_programs = `${this.apiHost}/${this.apiPath}/lookup/joint-programs`
    this.url_genders = `${this.apiHost}/${this.apiPath}/lookup/genders`
  }

  checkStatusCode(status: number): boolean {
    return status >= 200 && status < 300
  }

  public async getColleges(): Promise<Colleges> {
    const response = await this.useFetch('GET', 'getColleges', `${this.url_colleges}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getStudentTypes(): Promise<StundetTypes> {
    const response = await this.useFetch('GET', 'getStudentTypes', `${this.url_student_types}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartments(): Promise<Departments> {
    const response = await this.useFetch('GET', 'getDepartments', `${this.url_departments}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getConfigs(): Promise<Configs> {
    const response = await this.useFetch('GET', 'getConfigs', `${this.url_configs}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getCountries(): Promise<Countries> {
    const response = await this.useFetch('GET', 'getCountries', `${this.url_countries}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getPayTypes(): Promise<PayTypes> {
    const response = await this.useFetch('GET', 'getPayTypes', `${this.url_pay_types}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getYears(): Promise<Years> {
    const response = await this.useFetch('GET', 'getYears', `${this.url_years}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getVisitingDates(): Promise<VisitingDates> {
    const response = await this.useFetch('GET', 'getVisitingDates', `${this.url_visiting_dates}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getStates(): Promise<USStates> {
    const response = await this.useFetch('GET', 'getStates', `${this.url_states}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getLabs(): Promise<Labs> {
    const response = await this.useFetch('GET', 'getLabs', `${this.url_labs}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getJointPrograms(): Promise<JointPrograms> {
    const response = await this.useFetch('GET', 'getJointPrograms', `${this.url_joint_programs}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getGenders(): Promise<JointPrograms> {
    const response = await this.useFetch('GET', 'getGenders', `${this.url_genders}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
