import { EventBus, appInitialized } from '@mit/aws-react'
import { TextItem, LookUpController, ApplicationController } from 'Api/controller'
import { useStoreActions } from 'Store/hooks'
import { AppTexts } from 'Store/models/Visiting'
import { DocumentUpload } from 'Types/DocumentType'
import appTextData from '../visitingText.json'
import { useState, useEffect } from 'react';

const applicationController = new ApplicationController()
const lookUpController = new LookUpController()
interface UseAppInitializationReturn {
  initializeApp: () => void
}

//Remove later after API addition
const uploadArray: DocumentUpload[] = [
  {
    contentType: 'application/pdf',
    documentType: { id: '2', name: 'Mentoring Plan', canApprove: false },
    templateUrl: 'https://visiting-student-static-content-v1{env}.s3.amazonaws.com/Mentoring+Plan+Form.pdf',
    show: false,
    required: true
  },
  {
    contentType: 'application/pdf',
    documentType: { id: '9', name: 'Certificate of English', canApprove: false },
    templateUrl: 'https://visiting-student-static-content-v1{env}.s3.amazonaws.com/Certification+of+English+Proficiency+Form.pdf',
    show: false,
    required: false
  },
  {
    contentType: 'application/pdf',
    documentType: { id: '10', name: 'Department Invitation Letter', canApprove: false },
    templateUrl: 'https://visiting-student-static-content-v1{env}.s3.amazonaws.com/Department+Invitation+Letter.pdf',
    show: true,
    required: false
  }
]

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { setAppTexts } = useStoreActions((state) => state.VisitingModel)
  const { setAuth } = useStoreActions((actions) => actions.VisitingModel)
  const [initializeApi, setInitializeApi] = useState(false)
  const { setYear } = useStoreActions((action) => action.VisitingModel)
  const {
    setColleges,
    setDepartments,
    setCountries,
    setConfigs,
    setPayTypes,
    setStudentTypes,
    setYears,
    setVisitingDates,
    setStates,
    setMinStartDate,
    setMaxStartDate,
    setDocuments,
    setLabs,
    setJointPrograms,
    setGenders
  } = useStoreActions((actions) => actions.LookUpModel)


  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false,
    });
  }, []);

  const initializeApp = async (): Promise<void> => {

    const texts: AppTexts = appTextData
    setAppTexts(texts)

    let access: boolean = false
    await applicationController
      .getAuth()
      .then((auth) => {
        if (!auth.isAuthorized) {
          EventBus.dispatch('APP_INITIALIZED', {
            isLoading: false,
            hasAccess: false
          })
        } else {
          access = true
          setAuth(auth)
        }
      })
      .catch((err) => {
        console.log('Error in initializing the app', err)
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: true
        });

      })

    if (access) {

      try {

        const collegesPromise = lookUpController.getColleges()
        const departmentsPromise = lookUpController.getDepartments()
        const countriesPromise = lookUpController.getCountries()
        const payTypesPromise = lookUpController.getPayTypes()
        const studentTypesPromise = lookUpController.getStudentTypes()
        const yearsPromise = lookUpController.getYears()
        const visitingDatesPromise = lookUpController.getVisitingDates()
        const statesPromise = lookUpController.getStates()
        const configsPromise = lookUpController.getConfigs()
        const labsPromise = lookUpController.getLabs()
        const jointProgramsPromise = lookUpController.getJointPrograms()
        const gendersPromise = lookUpController.getGenders()

        Promise.allSettled([
          collegesPromise,
          departmentsPromise,
          countriesPromise,
          payTypesPromise,
          studentTypesPromise,
          yearsPromise,
          visitingDatesPromise,
          statesPromise,
          configsPromise,
          labsPromise,
          jointProgramsPromise,
          gendersPromise
        ])
          .then((resp) => {

            setInitializeApi(true);
            appInitialized({
              isLoading: false,
              hasAccess: true,
              hasError: false,
            });

            // Colleges list
            if (resp[0].status === 'fulfilled') {
              setColleges(resp[0].value.colleges)
            }
            // Departments list
            if (resp[1].status === 'fulfilled') {
              setDepartments(resp[1].value.departments)
              setDocuments(uploadArray)
              // if (resp[1].value?.departments?.length) setDepartment(resp[1].value.departments[0])
            }
            // Countries list
            if (resp[2].status === 'fulfilled') {
              setCountries(resp[2].value.countries)
            }
            // Available Pay Types list
            if (resp[3].status === 'fulfilled') {
              setPayTypes(resp[3].value.payTypes?.map(({ id, name, maxPercentageSplit }) => ({ id: `${id}`, name, maxPercentageSplit })))
            }
            // Student Types list
            if (resp[4].status === 'fulfilled') {
              setStudentTypes(resp[4].value.studentTypes)
            }
            // Years list
            if (resp[5].status === 'fulfilled') {
              const years = resp[5].value.years
              const currentYear = years?.find(row => row.isCurrentYear)
              setYears(resp[5].value.years)
              if (currentYear != null) {
                setYear({ ...currentYear, name: currentYear.id })

              }
              // if (resp[5].value.years) {
              //   const customYears = resp[5].value.years.map((year) => ({ ...year, name: year.id, isCurrentYear: year.isCurrentYear }))
              //   const selectedYear = customYears.find((item) => item.isCurrentYear)
              //   setYear(selectedYear)
              // }
            }
            // VisitingD ates list
            if (resp[6].status === 'fulfilled') {
              setVisitingDates(resp[6].value.items)
            }
            // VisitingD ates list
            if (resp[7].status === 'fulfilled') {
              setStates(resp[7].value.states)
            }
            // Config info
            if (resp[8].status === 'fulfilled') {
              setConfigs(resp[8].value.items ?? [])
              setMinStartDate(resp[8].value.items?.find((item) => item.id === 'MIN_VISITING_DATE')?.value ?? '')
              setMaxStartDate(resp[8].value.items?.find((item) => item.id === 'MAX_VISITING_DATE')?.value ?? '')
            }

            //Labs list
            if (resp[9].status === 'fulfilled') {
              setLabs(resp[9].value.items ?? [])
            }
            //Joint programs list
            if (resp[10].status === 'fulfilled') {
              setJointPrograms(resp[10].value.items ?? [])
            }
            if (resp[11].status === 'fulfilled') {
              setGenders(resp[11].value.items ?? [])
            }
          })
          .catch((err) => {
            console.error(err)
            appInitialized({
              isLoading: false,
              hasAccess: false,
              hasError: true
            });
          })

      } catch (err) {
        console.error(err)
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: true
        });
      }
    }

  }


  return { initializeApp }
}
