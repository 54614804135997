import { Action, action } from 'easy-peasy'

interface State {
  reload: boolean
  reloadStudents: boolean
  reloadCapacity: boolean
}
interface Actions {
  setReload: Action<this, boolean>
  setReloadStudents: Action<this, boolean>
  setReloadCapacity: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {}

export interface IReloadModel extends State, Actions, Thunks {}

export const ReloadModel: IReloadModel = {
  reload: false,
  reloadStudents: false,
  reloadCapacity: false,

  setReload: action((state, payload) => {
    state.reload = payload
  }),

  setReloadStudents: action((state, payload) => {
    state.reloadStudents = payload
  }),

  setReloadCapacity: action((state, payload) => {
    state.reloadCapacity = payload
  })
}
