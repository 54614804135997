import { BaseController } from './BaseController'
import { components } from 'Types/api'

export type ApplicationSummaries = components['schemas']['ApplicationSummaries']
export type ExistingStudent = components['schemas']['ExistingStudent']
export type StudentsStatus = components['schemas']['StudentsStatus']
export type StudentStatus = components['schemas']['StudentStatus']

export class StudentsController extends BaseController {
  readonly url_stundets_applicatiions: string
  readonly url_stundets_approvals: string
  readonly url_stundets_search: string
  readonly url_stundets_status: string

  constructor() {
    super()

    this.url_stundets_applicatiions = `${this.apiHost}/${this.apiPath}/students/applications`
    this.url_stundets_approvals = `${this.apiHost}/${this.apiPath}/students/approvals`
    this.url_stundets_search = `${this.apiHost}/${this.apiPath}/students`
    this.url_stundets_status = `${this.apiHost}/${this.apiPath}/students/status`
  }

  checkStatusCode(status: number): boolean {
    return status >= 200 && status < 300
  }

  public async getStudents(
    year?: string,
    departmentId?: string,
    mitId?: string,
    collegeId?: string,
    isAwaitingApproval?: boolean,
    status?: boolean
  ): Promise<ApplicationSummaries> {
    const query = [
      year !== '' && year !== undefined ? `yearId=${year}` : null,
      departmentId !== '' && departmentId !== undefined ? `departmentId=${encodeURIComponent(departmentId)}` : null,
      mitId !== '' && mitId !== undefined ? `mitId=${mitId}` : null,
      collegeId !== '' && collegeId !== undefined ? `collegeId=${collegeId}` : null,
      isAwaitingApproval !== undefined ? 'isAwaitingApproval=true' : null,
      status !== undefined ? `active=${status}` : null
    ]
      .filter((filter) => filter !== null)
      .join('&')

    const response = await this.useFetch('GET', 'students', `${this.url_stundets_applicatiions}?${query}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getStudentsApprovals(): Promise<ApplicationSummaries> {
    const response = await this.useFetch('GET', 'students', `${this.url_stundets_approvals}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async studentSearch(mitid: string): Promise<ExistingStudent> {
    const response = await this.useFetch('GET', 'studentSearch', `${this.url_stundets_search}?mitId=${mitid}`)

    if (this.checkStatusCode(response.status)) {
      const data: ExistingStudent = await response.json()
      if (data.dob !== undefined) {
        data.dob = data.dob?.replaceAll('-', '/') ?? ''
      }
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getStudentsStatus(startDate: string, endDate: string): Promise<StudentsStatus> {
    const response = await this.useFetch('GET', 'studentsStatus', `${this.url_stundets_status}?startDate=${startDate}&endDate=${endDate}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
