import { Action, action } from 'easy-peasy'
import { Authorization, Department, ApplicationSummaries, ApplicationExt, Approval, YearType } from 'Api/controller'
import { AlertMessage } from 'Types/MessageResponse'
import { EditStates } from 'Types/EditType'
import { FileToUpload } from 'Types/DocumentType'

export interface StudentApplicationStatus {
  id: string
  name: string
  value: boolean
}

export interface AppTexts {
  [key: string]: string
}
interface State {
  auth: Authorization
  appTexts: AppTexts
  applications: ApplicationSummaries
  applicationId: string
  mitId: string
  approval: Approval | undefined
  userApplication: ApplicationExt | undefined
  school: string
  newStudent: boolean
  searchKey: string
  department: Department
  year: YearType
  updateNeeded: AlertMessage
  existingStudent: boolean
  canEdit: EditStates
  documentsUploaded: FileToUpload[] | []
  status: StudentApplicationStatus
}
interface Actions {
  setAuth: Action<this, Authorization>
  setAppTexts: Action<this, AppTexts>
  setApplications: Action<this, ApplicationSummaries>
  setApplicationId: Action<this, string>
  setMitId: Action<this, string>
  setApproval: Action<this, Approval>
  setUserApplication: Action<this, ApplicationExt | undefined>
  setSchool: Action<this, string>
  setNewStudent: Action<this, boolean>
  setSearchKey: Action<this, string>
  setDepartment: Action<this, Department>
  setYear: Action<this, YearType>
  setUpdateNeeded: Action<this, AlertMessage>
  setExistingStudent: Action<this, boolean>
  setCanEdit: Action<this, EditStates>
  setDocumentsUploaded: Action<this, FileToUpload[] | []>
  setStatus: Action<this, StudentApplicationStatus>
}
// eslint-disable-next-line
interface Thunks {}

export interface IVisitingModel extends State, Actions, Thunks {}

export const VisitingModel: IVisitingModel = {
  auth: {
    isAuthorized: false,
    isDepartmentAdmin: false,
    isDepartmentApprover: false,
    isSchoolApprover: false,
    canManageApprovals: false,
    canViewSchoolCapacity: false,
    canManageCapacity: false,
    canSearchStudent: true,
    canViewStatus: false,
    canViewBillingReport: false,
    departmentAdminDlcKeys: []
  },
  appTexts: {},
  applications: { applicationSummaries: [] },
  applicationId: '',
  mitId: '',
  approval: undefined,
  userApplication: undefined,
  school: '',
  newStudent: false,
  searchKey: '',
  department: {
    collegeId: 'D_SCHOOL_ENG',
    id: 'D_AEROASTRO',
    name: 'Aeronautics and Astronautics'
  },
  year: {
    name: '',
    id: '',
    isCurrentYear: false
  },
  updateNeeded: { severity: 'info', show: false, message: '' },
  existingStudent: false,
  canEdit: {
    canEditAddress: false,
    canEditBilling: false,
    canEditDates: false,
    canEditExemptions: false,
    canEditForm: false,
    canEditHostDetails: false,
    canEditUserDetails: false,
    canEditGrade20Details: false,
    canExtend: false,
    canUploadDocuments: false,
    canUpdateStatus: false
  },
  documentsUploaded: [],
  status: {
    id: '1',
    name: 'Active',
    value: true
  },

  setAuth: action((state, payload) => {
    state.auth = payload
  }),

  setAppTexts: action((state, payload) => {
    state.appTexts = payload
  }),

  setApplications: action((state, payload) => {
    state.applications = payload
  }),

  setApplicationId: action((state, payload) => {
    state.applicationId = payload
  }),

  setMitId: action((state, payload) => {
    state.mitId = payload
  }),

  setApproval: action((state, payload) => {
    state.approval = payload
  }),

  setUserApplication: action((state, payload) => {
    state.userApplication = payload
  }),

  setSchool: action((state, payload) => {
    state.school = payload
  }),

  setNewStudent: action((state, payload) => {
    state.newStudent = payload
  }),

  setSearchKey: action((state, payload) => {
    state.searchKey = payload
  }),

  setDepartment: action((state, payload) => {
    state.department = payload
  }),

  setYear: action((state, payload) => {
    state.year = payload
  }),

  setUpdateNeeded: action((state, payload) => {
    state.updateNeeded = payload
  }),

  setExistingStudent: action((state, payload) => {
    state.existingStudent = payload
  }),

  setCanEdit: action((state, payload) => {
    state.canEdit = payload
  }),

  setDocumentsUploaded: action((state, payload) => {
    state.documentsUploaded = payload
  }),

  setStatus: action((state, payload) => {
    state.status = payload
  })
}
