import { RowsData } from 'Common/GenericTable'
import dayjs from 'dayjs'
import { Action, action } from 'easy-peasy'

interface State {
    searchKeyDates: { startDate: string, endDate: string }
    searchKeyStudent: string
    currentStudentStatusRows: RowsData[]
}
interface Actions {
    setSearchKeyDates: Action<this, { startDate: string, endDate: string }>
    setSearchKeyStudent: Action<this, string>
    setCurrentStudentStatusRows: Action<this, RowsData[]>
}

export interface IStatusModel extends State, Actions { }

export const StatusModel: IStatusModel = {
    searchKeyDates: { startDate: dayjs().startOf('year').format('YYYY/MM/DD'), endDate: dayjs().add(14, "day").format('YYYY/MM/DD') },
    searchKeyStudent: '',
    currentStudentStatusRows: [],

    setSearchKeyDates: action((state, payload) => {
        state.searchKeyDates.startDate = payload.startDate
        state.searchKeyDates.endDate = payload.endDate
    }),

    setSearchKeyStudent: action((state, payload) => {
        state.searchKeyStudent = payload
    }),

    setCurrentStudentStatusRows: action((state, payload) => {
        state.currentStudentStatusRows = payload
    })
}
