import { Action, action } from 'easy-peasy'
import { College, Country, Department, PayType, StundetType, Year, Config, VisitingDate, USState, Lab, JointProgram, Gender } from 'Api/controller'
import { DocumentUpload } from 'Types/DocumentType'

interface State {
  colleges: College[] | undefined
  college: College | undefined
  departments: Department[] | undefined
  configs: Config[]
  countries: Country[] | undefined
  payTypes: PayType[] | undefined
  studentTypes: StundetType[] | undefined
  years: Year[] | undefined
  visitingDates: VisitingDate[] | undefined
  states: USState[] | undefined
  minStartDate: string
  maxStartDate: string
  documents: DocumentUpload[]
  labs: Lab[]
  jointPrograms: JointProgram[]
  genders: Gender[]
}
interface Actions {
  setColleges: Action<this, College[] | undefined>
  setCollege: Action<this, College | undefined>
  setDepartments: Action<this, Department[] | undefined>
  setConfigs: Action<this, Config[]>
  setCountries: Action<this, Country[] | undefined>
  setPayTypes: Action<this, PayType[] | undefined>
  setStudentTypes: Action<this, StundetType[] | undefined>
  setYears: Action<this, Year[] | undefined>
  setVisitingDates: Action<this, VisitingDate[] | undefined>
  setStates: Action<this, USState[] | undefined>
  setMinStartDate: Action<this, string>
  setMaxStartDate: Action<this, string>
  setDocuments: Action<this, DocumentUpload[] | []>
  setLabs: Action<this, Lab[] | []>
  setJointPrograms: Action<this, JointProgram[] | []>
  setGenders: Action<this, Gender[] | []>
}
// eslint-disable-next-line
interface Thunks {}
// let render = 0
export interface ILookUpModel extends State, Actions, Thunks {}

export const LookUpModel: ILookUpModel = {
  colleges: [],
  college: undefined,
  departments: [],
  configs: [],
  countries: [],
  payTypes: [],
  studentTypes: [],
  years: [],
  visitingDates: [],
  states: [],
  minStartDate: '',
  maxStartDate: '',
  documents: [],
  labs: [],
  jointPrograms: [],
  genders: [],

  setColleges: action((state, payload) => {
    state.colleges = payload
  }),

  setCollege: action((state, payload) => {
    state.college = payload
  }),

  setDepartments: action((state, payload) => {
    state.departments = payload
  }),

  setConfigs: action((state, payload) => {
    state.configs = payload
  }),

  setCountries: action((state, payload) => {
    state.countries = payload
  }),

  setPayTypes: action((state, payload) => {
    state.payTypes = payload
  }),

  setStudentTypes: action((state, payload) => {
    state.studentTypes = payload
  }),

  setYears: action((state, payload) => {
    state.years = payload
  }),

  setVisitingDates: action((state, payload) => {
    state.visitingDates = payload
  }),

  setStates: action((state, payload) => {
    state.states = payload
  }),

  setMinStartDate: action((state, payload) => {
    state.minStartDate = payload
  }),

  setMaxStartDate: action((state, payload) => {
    state.maxStartDate = payload
  }),

  setDocuments: action((state, payload) => {
    state.documents = payload
  }),

  setLabs: action((state, payload) => {
    state.labs = payload
  }),

  setJointPrograms: action((state, payload) => {
    state.jointPrograms = payload
  }),

  setGenders: action((state, payload) => {
    state.genders = payload
  })
}
