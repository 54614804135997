import { BaseController } from './BaseController'
import { components } from 'Types/api'

export type Authorization = components['schemas']['Authorization']
export type Application = components['schemas']['Application']
export type ApplicationStatus = components['schemas']['ApplicationStatus']
export type Document = components['schemas']['Document']
export type Task = components['schemas']['Task']
export type Message = components['schemas']['MessageResponse']
export type Approval = components['schemas']['Approval']
export type Approvals = components['schemas']['Approvals']
export type Student = components['schemas']['ApplicationSummary']
export type StudentType = components['schemas']['StudentType']
export type Status = components['schemas']['Status']
export type StatusType = components['schemas']['StatusType']
export type ApplicationPayType = components['schemas']['ApplicationPayType']
export type Invoice = components['schemas']['ApplicationInvoice']
export type InvoiceType = components['schemas']['InvoiceStatusType']
export type RefundRequest = components['schemas']['RefundRequest']

export interface TextItem {
  id: string
  text: string
}

// export interface ApplicationPayTypeExt extends ApplicationPayType {
//   active: boolean
// }

export interface ApplicationExt extends Application {
  existingStudent?: any
  studentPay?: boolean
  facultyPay?: boolean
  partyPay?: boolean
}

export interface RefundRequestI {
  comment: string
  dateLeft: string | null
}

export class ApplicationController extends BaseController {
  readonly url_authorization: string
  readonly url_application: string
  readonly url_texts: string
  readonly url_refund_request: string

  constructor() {
    super()

    this.url_authorization = `${this.apiHost}/${this.apiPath}/authorization`
    this.url_application = `${this.apiHost}/${this.apiPath}/applications`
    this.url_texts = `${this.apiHost}/${this.apiPath}/texts`
    this.url_refund_request = `${this.apiHost}/${this.apiPath}/refund-request`
  }

  checkStatusCode(status: number): boolean {
    return status >= 200 && status < 300
  }

  public async getAuth(): Promise<Authorization> {
    const response = await this.useFetch('GET', 'authorization', this.url_authorization)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      // return Promise.reject(data)
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getAppTexts(): Promise<TextItem[]> {
    const response = await this.useFetch('GET', 'appTexts', this.url_texts)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async postApplication(body: Application): Promise<Application> {
    const response = await this.useFetch('POST', 'postApplication', this.url_application, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getApplication(applicationId: string): Promise<Application> {
    const response = await this.useFetch('GET', 'getApplication', `${this.url_application}/${applicationId}`)

    if (this.checkStatusCode(response.status)) {
      const data: Application = await response.json()
      if (data.student.dob !== undefined) {
        data.student.dob = data.student.dob?.replaceAll('-', '/') ?? ''
      }

      if (data.student.homeInstitutionCountry && data.student.homeInstitutionCountry.id === 'null') {
        data.student.homeInstitutionCountry = null
      }

      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async patchApplication(applicationId: string, body: Application): Promise<Application> {
    const response = await this.useFetch('PATCH', 'patchApplication', `${this.url_application}/${applicationId}`, body)

    if (this.checkStatusCode(response.status)) {
      const data: Application = await response.json()
      if (data.student.dob !== undefined) {
        data.student.dob = data.student.dob?.replaceAll('-', '/') ?? ''
      }
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async updateApplicationStatus(applicationId: string, body: ApplicationStatus): Promise<Application> {
    const response = await this.useFetch('PATCH', 'updateApplicationStatus', `${this.url_application}/${applicationId}/status`, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getApplicationDocuments(applicationId: string, documentId: string): Promise<Document> {
    const response = await this.useFetch('GET', 'getApplicationDocuments', `${this.url_application}/${applicationId}/documents/${documentId}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async patchApplicationDocuments(applicationId: string, documentId: string, body: Status): Promise<void> {
    const response = await this.useFetch('PATCH', 'patchApplicationDocuments', `${this.url_application}/${applicationId}/documents/${documentId}`, body)

    if (this.checkStatusCode(response.status)) {
      return
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async deleteApplicationDocuments(applicationId: string, documentId: string): Promise<void> {
    const response = await this.useFetch('DELETE', 'deleteApplicationDocuments', `${this.url_application}/${applicationId}/documents/${documentId}`)

    if (this.checkStatusCode(response.status)) {
      return
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getApplicationApprovals(applicationId: string): Promise<Approvals> {
    const response = await this.useFetch('GET', 'getApplicationApprovals', `${this.url_application}/${applicationId}/approvals`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async postApplicationApprovals(applicationId: string, body: Approval): Promise<Approval> {
    const response = await this.useFetch('POST', 'postApplicationApprovals', `${this.url_application}/${applicationId}/approvals`, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getApplicationApproval(applicationId: string, approvalId: string): Promise<Approval> {
    const response = await this.useFetch('GET', 'getApplicationApproval', `${this.url_application}/${applicationId}/approvals/${approvalId}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async patchApplicationApproval(applicationId: string, approvalId: string, body: Status): Promise<Message> {
    const response = await this.useFetch('PATCH', 'patchApplicationApproval', `${this.url_application}/${applicationId}/approvals/${approvalId}`, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.json()
    return await Promise.reject(text)
  }

  public async deleteApplicationApproval(applicationId: string, approvalId: string): Promise<Message> {
    const response = await this.useFetch('DELETE', 'deleteApplicationApproval', `${this.url_application}/${applicationId}/approvals/${approvalId}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async patchRefundRequest(applicationId: string, body: RefundRequestI): Promise<Message> {
    const response = await this.useFetch('POST', 'requestRefund', `${this.url_application}/${applicationId}/refund-request`, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async resendStudentRegistration(applicationId: string): Promise<void> {
    const response = await this.useFetch('POST', 'resendStudentRegistration', `${this.url_application}/${applicationId}/resend-registration`)

    if (this.checkStatusCode(response.status)) {
      return
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
